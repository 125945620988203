import type { ContactFormType } from '../pages/contact-us';

export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

type ContactProps = Partial<Omit<ContactFormType, 'subject'> & { subject: string }>;

interface PixelBaseType {
  content_ids: (string | number)[];
  content_name: string;
  contents: {
    id: string | number;
    quantity: number;
  };
  currency: string;
  value: number;
}

type PixelBaseContentType = Omit<PixelBaseType, 'contents'>;
type PixelBaseProductType = Omit<PixelBaseType, 'content_ids'>;

type PixelPropType = (PixelBaseContentType | PixelBaseProductType) & { num_items: number };
type PixelPaymentInfoPropType =
  | Omit<PixelBaseContentType, 'content_name'>
  | Omit<PixelBaseProductType, 'content_name'>;
type PixelAddToCartPropType = PixelBaseContentType | PixelBaseProductType;
type PixelRegistrationPropType = Omit<PixelBaseType, 'contents' | 'content_ids'>;

export const pageView = (props: Partial<PixelPropType>): void => {
  window.fbq('track', 'ViewContent', { ...props, content_type: 'product' });
};

export const addPaymentInfo = (props: PixelPaymentInfoPropType): void => {
  window.fbq('track', 'AddPaymentInfo', props);
};

export const addToCart = (props: PixelAddToCartPropType): void => {
  window.fbq('track', 'AddToCart', { ...props, content_type: 'product' });
};

export const completeRegistration = (props: PixelRegistrationPropType): void => {
  window.fbq('track', 'CompleteRegistration', { ...props, status: true });
};

export const initiateCheckout = (props: PixelPropType): void => {
  window.fbq('track', 'InitiateCheckout', { ...props, content_type: 'product' });
};

export const purchase = (props: PixelPropType): void => {
  window.fbq('track', 'Purchase', { ...props, content_type: 'product' });
};

export const contact = (props: ContactProps): void => {
  window.fbq('track', 'Contact', props);
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name: string, options = {}): void => {
  window.fbq('trackCustom', name, options);
};
