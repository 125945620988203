/**
 * @typedef {Object} Time
 * @property {number} hours - Number of hours
 * @property {number} minutes - Number of minutes
 */
/**
 * Turn minutes to Hours and minutes
 *
 * @param mins - time in minutes
 * @returns Time in hours and minutes
 */
export const getTime = (mins: number): { hours: number; minutes: number } => ({
  hours: Math.floor(mins / 60),
  minutes: mins % 60,
});
