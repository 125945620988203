import { createContext, useContext, useEffect, useState } from 'react';

import { firebase } from '@app/config/fire-conf';
import { Cinema } from '@app/types/cinema';
import { AvailableOn } from '@app/types/movie';
import { getAvailableCinemas } from '@app/utils';

type CinemasProviderValueType = {
  allCinemas: Cinema[];
  availableOn: (available: AvailableOn) => Cinema[];
};

type CinemasProviderPropType = {
  children: React.ReactNode;
};

const CinemasContext = createContext<CinemasProviderValueType>({} as CinemasProviderValueType);

export const CinemasProvider = ({ children }: CinemasProviderPropType): JSX.Element => {
  const [cinemas, setCinemas] = useState<Cinema[]>([]);

  const availableCinemasList = (available: AvailableOn) => {
    return cinemas.filter((cinema) => getAvailableCinemas(available).includes(cinema.id));
  };

  const getCinemas = async () => {
    const cinemasSnapshot = await firebase.firestore().collection('cinemas').get();
    const currentCinemas: Cinema[] = [];
    cinemasSnapshot.forEach((cinema) => {
      currentCinemas.push({
        ...(cinema.data() as Cinema),
        id: cinema.id,
      });
    });

    // available On

    setCinemas(currentCinemas);
  };

  useEffect(() => {
    getCinemas();
  }, []);

  return (
    <CinemasContext.Provider value={{ allCinemas: cinemas, availableOn: availableCinemasList }}>
      {children}
    </CinemasContext.Provider>
  );
};

export const useCinemas = (): CinemasProviderValueType => {
  return useContext(CinemasContext);
};
