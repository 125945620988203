import { useEffect } from 'react';
import { AppProps } from 'next/app';
import Script from 'next/script';
import { DefaultSeo } from 'next-seo';
import { useRouter } from 'next/router';

import defaultSEOConfig from '@app/config/seo.config';
import { pageView, FB_PIXEL_ID } from '@app/lib/facebook-pixel';
import { CinemasProvider } from '@app/context/cinemas';
import { RemoteConfigProvider } from '@app/context/remote-config';

import '../styles/globals.css';

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  const router = useRouter();

  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    pageView({
      content_ids: [window.location.pathname],
      content_name: document.title,
    });

    const handleRouteChange = (url: string) => {
      pageView({
        content_ids: [url],
        content_name: document.title,
      });
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
  return (
    <>
      <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${FB_PIXEL_ID});
          `,
        }}
      />
      <RemoteConfigProvider>
        <CinemasProvider>
          <DefaultSeo {...defaultSEOConfig} />
          <Component {...pageProps} />
        </CinemasProvider>
      </RemoteConfigProvider>
    </>
  );
}

export default MyApp;
