import { getDay } from 'date-fns';

export const getDayOfWeek = function getDayOfWeek(date: Date): number {
  let dayOfWeek = getDay(date); // 0 represent sunday
  dayOfWeek -= 1;
  if (dayOfWeek === -1) {
    dayOfWeek = 6; // move sundays to 6th
  }
  return dayOfWeek;
};
