import { DefaultSeoProps } from 'next-seo';

const defaultSEOConfig: DefaultSeoProps = {
  titleTemplate: `%s - ${process.env.NEXT_PUBLIC_SEO_DEFAULT_TITLE}`,
  defaultTitle: process.env.NEXT_PUBLIC_SEO_TITLE,
  description: process.env.NEXT_PUBLIC_SEO_DESCRIPTION,
  /** STOP Search Engine */
  dangerouslySetAllPagesToNoIndex: process.env.NEXT_PUBLIC_NO_SEARCH_ENGINE === 'true',
  dangerouslySetAllPagesToNoFollow: process.env.NEXT_PUBLIC_NO_SEARCH_ENGINE === 'true',
  /** STOP Search Engine */
  additionalMetaTags: [
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, viewport-fit=cover',
    },
    {
      name: 'theme-color',
      content: `${process.env.NEXT_PUBLIC_APP_THEME_COLOR}`,
    },
    {
      name: 'msapplication-TileColor',
      content: `${process.env.NEXT_PUBLIC_APP_THEME_COLOR}`,
    },
    {
      name: 'msapplication-TileColor',
      content: `${process.env.NEXT_PUBLIC_APP_THEME_COLOR}`,
    },
    {
      name: 'application-name',
      content: `${process.env.NEXT_PUBLIC_APP_NAME}`,
    },
    {
      name: 'apple-mobile-web-app-title',
      content: `${process.env.NEXT_PUBLIC_APP_NAME}`,
    },
  ],
  additionalLinkTags: [
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicon-32x32.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicon-16x16.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/apple-touch-icon.png',
    },
    {
      rel: 'manifest',
      href: '/site.webmanifest',
    },
    {
      rel: 'mask-icon',
      href: '/safari-pinned-tab.svg',
      color: process.env.NEXT_PUBLIC_APP_THEME_COLOR,
    },
  ],
  twitter: {
    cardType: process.env.NEXT_PUBLIC_SEO_TWITTER_TYPE,
    site: process.env.NEXT_PUBLIC_SEO_TWITTER_SITE_HANDLER,
  },
  facebook: process.env.NEXT_PUBLIC_SEO_FACEBOOK_APP_ID
    ? {
        appId: process.env.NEXT_PUBLIC_SEO_FACEBOOK_APP_ID,
      }
    : undefined,
  openGraph: {
    type: process.env.NEXT_PUBLIC_SEO_TYPE,
    title: process.env.NEXT_PUBLIC_SEO_TITLE,
    description: process.env.NEXT_PUBLIC_SEO_DESCRIPTION,
    url: process.env.NEXT_PUBLIC_SITE_DOMAIN,
    images: process.env.NEXT_PUBLIC_SEO_BASE_IMAGE
      ? [
          {
            url: process.env.NEXT_PUBLIC_SEO_BASE_IMAGE,
            width: parseInt(process.env.NEXT_PUBLIC_SEO_BASE_IMAGE_WIDTH || '800', 10),
            height: parseInt(process.env.NEXT_PUBLIC_SEO_BASE_IMAGE_HEIGHT || '600', 10),
            alt: process.env.NEXT_PUBLIC_SEO_TITLE,
          },
        ]
      : undefined,
  },
};

export default defaultSEOConfig;
